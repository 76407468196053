#home-view {
    letter-spacing: .5px;
    font-family: roboto,sans-serif;
    font-size: 15px;
    background-color: #efefef;
    position: fixed;
    overflow-y: scroll;
}

#home-view .title {
    font-size: xx-large;
    letter-spacing: 3px;
}

#home-view .language-switcher {
    letter-spacing: 3px;
}

#home-view .title>.bold {
    font-weight: 900;
}

#home-view .reach-us {
    font-size: 12px;
}

#home-view h4 {
    letter-spacing: 4px;
}