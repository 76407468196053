.primary-color {
  color: #00BFA6;
}

.secondary-color {
  color: #F50057;
}

.cursor-pointer {
  cursor: pointer;
}